import ErrorIcon from '@mui/icons-material/Error'
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	SvgIconProps,
	SvgIconPropsColorOverrides,
	TextField,
	Typography,
} from '@mui/material'
import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close'

import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle'
import dayjs from 'dayjs'
import { ShiftEndTaskSummary } from '../../../../types/Reports/ShiftEnd/ShiftEndWorkItems'
import { updateWorkItemProgress } from '../../../../services/workItemService'
import { showErrorToast } from '../../../../services/alertServices'

type ProgressDialogProps = {
	task: ShiftEndTaskSummary
	uom: string
	workItemId: number
	loadData: () => void
}

export const ProgressDialog = ({ task, uom, workItemId, loadData }: ProgressDialogProps) => {
	const [open, setOpen] = React.useState(false)
	const [progressValue, setProgressValue] = React.useState<number>()
	const [endTime, setEndTime] = React.useState<Date>()
	const [submitting, setSubmitting] = React.useState<boolean>(false)

	React.useEffect(() => {
		return () => setProgressValue(0)
	}, [])

	const handleClickOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		setOpen(true)
	}
	const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		setOpen(false)
	}

	function isValid() {
		return progressValue && progressValue <= task.maxQuantity
	}

	async function handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.stopPropagation()
		if (isValid()) {
			setSubmitting(true)
			updateWorkItemProgress(workItemId, task.id, progressValue!)
				.then((res) => {
					setOpen(false)
			        loadData()
				})
				.catch((ex) => {
					showErrorToast(ex)
				})
				.finally(() => {
					setSubmitting(false)
				})
		}
	}

	return (
		<>
			<Button
				onClick={handleClickOpen}
				title='Progress'
				color='primary'
				variant='contained'
				size='small'
			>
				<PlaylistAddCheckCircleIcon /> Progress
			</Button>

			<Dialog open={open} onClose={handleClose} fullWidth>
				<div
					onClick={(e: React.MouseEvent<HTMLDivElement>) => {
						e.stopPropagation() // Prevent any event inside the dialog from bubbling up
					}}
				>
					<DialogTitle>Progress {task.title}</DialogTitle>
					<IconButton
						onClick={handleClose}
						sx={(theme) => ({
							position: 'absolute',
							right: 8,
							top: 8,
							color: theme.palette.grey[500],
						})}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent>
						<Box sx={{ display: 'flex', flexDirection: 'column' }} gap={4}>
							<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} gap={2}>
								<InputLabel htmlFor='progress-text'>{task.quantityComplete} + </InputLabel>
								<TextField
									sx={{ backgroundColor: !isValid() ? 'rgba(255,230,222,1)' : 'inherit' }}
									type='number'
									id='progress-text'
									size='small'
									value={progressValue}
									onChange={(e) => setProgressValue(parseFloat(e.target.value))}
									inputProps={{
										min: 0,
									}}
									InputProps={{
										startAdornment: <InputAdornment position='start'>{uom}</InputAdornment>,
									}}
								/>
							</Box>
						</Box>
					</DialogContent>
					<DialogActions sx={{ justifyContent: 'space-between' }}>
						<Button color='warning' variant='contained' onClick={handleClose}>
							Cancel
						</Button>
						{submitting ? (
							<CircularProgress color='success' />
						) : (
							<Button color='success' variant='contained' onClick={handleSubmit}>
								Submit
							</Button>
						)}
					</DialogActions>
				</div>
			</Dialog>
		</>
	)
}
