import { Box, Chip, IconButton, TableRow } from '@mui/material'
import * as React from 'react'
import { StyledTableCell } from '../../../core/StyledTableCell'
import {
	ShiftEndTaskSummary,
	ShiftEndWorkItemsSummary,
} from '../../../../types/Reports/ShiftEnd/ShiftEndWorkItems'
import { formatPercent } from '../../../../services/DataFormatters'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useState } from 'react'
import { ProgressDialog } from './ProgressDialog'
import AnimatedNumberField from '../../../core/AnimatedNumberField'

const WorkerSummaryRow = ({
	workItem,
	loadData,
}: {
	workItem: ShiftEndWorkItemsSummary
	loadData: () => void
}) => {
	const [open, setOpen] = useState(false)
	return (
		<>
			<TableRow key={workItem.workerId} sx={{ cursor: 'pointer' }}>
				<StyledTableCell>
					{(workItem?.tasks?.length ?? 0) > 0 && (
						<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</StyledTableCell>
				<StyledTableCell align='center'>
					<Chip label={workItem.type} variant={'backlogWorkItem'} />
				</StyledTableCell>
				<StyledTableCell align='left' sx={{ pr: 0 }}>
					{workItem.title.split(' > ').map(
						(wi, index, arr) =>
							index !== arr.length - 1 && (
								<>
									{index + 1}. {wi} <br />
								</>
							),
					)}
				</StyledTableCell>
				<StyledTableCell align='center' sx={{ pl: 0 }}>
					{workItem.quantityBudgeted}
				</StyledTableCell>
				<StyledTableCell align='center' sx={{ pl: 0 }}></StyledTableCell>
				<StyledTableCell align='center'>{workItem.burnedHours}</StyledTableCell>
				<StyledTableCell align='center'>
					<AnimatedNumberField value={workItem.performanceFactor} />{' '}
				</StyledTableCell>
				<StyledTableCell align='center'>
					<AnimatedNumberField value={formatPercent(workItem.percentComplete)} />{' '}
				</StyledTableCell>
				<StyledTableCell align='center'>{workItem.budgetedHours}</StyledTableCell>
				<StyledTableCell align='center' sx={{ pl: 0 }}></StyledTableCell>
				<StyledTableCell align='center'>
					<Chip label={workItem.status} variant={workItem.status} />
				</StyledTableCell>
			</TableRow>
			{open && (
				<>
					{(workItem.tasks ?? []).map((task: ShiftEndTaskSummary, index) => (
						<TableRow key={index}>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell align='center'>
								<Chip label={task.type} variant={'task'} />
							</StyledTableCell>
							<StyledTableCell align='left' sx={{ pr: 0 }}>
								{task.title}
							</StyledTableCell>
							<StyledTableCell align='center' sx={{ pl: 0 }}></StyledTableCell>
							<StyledTableCell align='center' sx={{ pl: 0 }}>
								<AnimatedNumberField value={task.quantityComplete} />{' '}
							</StyledTableCell>

							<StyledTableCell align='center'>{task.burnedHours}</StyledTableCell>
							<StyledTableCell align='center'></StyledTableCell>
							<StyledTableCell align='center'>
								<AnimatedNumberField value={formatPercent(task.percentComplete)} />
							</StyledTableCell>
							<StyledTableCell align='center'></StyledTableCell>
							<StyledTableCell align='center' sx={{ pl: 0 }}>
								<ProgressDialog
									task={task}
									uom={workItem.unitOfMeasure}
									workItemId={workItem.workItemId}
									loadData={loadData}
								/>
							</StyledTableCell>
							<StyledTableCell align='center'>
								<Chip label={task.status} variant={task.status} />
							</StyledTableCell>
						</TableRow>
					))}
				</>
			)}
		</>
	)
}

export default WorkerSummaryRow
