import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import * as React from 'react'
import { StyledTableCell } from '../../../core/StyledTableCell'
import { ShiftEndWorkItemsSummary } from '../../../../types/Reports/ShiftEnd/ShiftEndWorkItems'
import WorkerSummaryRow from './WorkerSummaryRow'


type workItemsProps = {
	workItemData: ShiftEndWorkItemsSummary[]
	loadData: () => void
}

const WorkerSummaryGrid = ({ workItemData, loadData }: workItemsProps): JSX.Element => {
	return (
		<TableContainer
			sx={{
				height: 'calc(80vh - 56px)',
				width: '100%',
				overflowX: 'scroll',
				overflowY: 'scroll',
			}}
		>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<StyledTableCell align='center'></StyledTableCell>
						<StyledTableCell align='center'>Type</StyledTableCell>
						<StyledTableCell align='left'>Title</StyledTableCell>
						<StyledTableCell align='center' sx={{pl: 0}}>Qty Budgeted</StyledTableCell>
						<StyledTableCell align='center' sx={{pl: 0}}>Qty Complete</StyledTableCell>
						<StyledTableCell align='center'>Actual Hours</StyledTableCell> {/* Burned Hours */}
						<StyledTableCell align='center'>PF</StyledTableCell>
						<StyledTableCell align='center'>% Complete</StyledTableCell>
						<StyledTableCell align='center'>Budgeted Hours</StyledTableCell>
						<StyledTableCell align='center'>Action</StyledTableCell>
						<StyledTableCell align='center'>Status</StyledTableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{(workItemData ?? []).map((workItem: ShiftEndWorkItemsSummary, index: number) => (
						<WorkerSummaryRow key={index} workItem={workItem} loadData={loadData}/>
					))}
					{(workItemData?.length ?? 0) === 0 && (
						<TableRow>
							<StyledTableCell align='center' scope='row' colSpan={17}>
								<h4 style={{ fontStyle: 'italic' }}>No Time Sheets were found for this Foreman</h4>
							</StyledTableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default WorkerSummaryGrid