import { Chip, IconButton, TableRow } from '@mui/material'
import * as React from 'react'
import { TimeSheet } from '../../../../types/Reports/ShiftEnd/TimeSheet'
import { Activity } from '../../../../types/Reports/ShiftEnd/Activity'
import { useShiftEndContext } from '../ShiftEndContext'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { StyledTableCell } from '../../../core/StyledTableCell'
import AnimatedNumberField from '../../../core/AnimatedNumberField'
import TimePickerCell from './TimePickerCell'
import RemoveUnproductiveDialog from './RemoveUnproductiveDialog'
import { useParams } from 'react-router-dom'
import { useDecodeToken } from '../../../../security/useDecodeToken'

dayjs.extend(duration)

type ActivityRowProps = {
	timesheet: TimeSheet
	open: boolean
}

const ActivityRows = ({ timesheet, open }: ActivityRowProps) => {
	const { updateTimeSheet, removeUnproductiveActivity } = useShiftEndContext()
	const token = useDecodeToken()
	const params = useParams()
	const viewAsId = token?.role === 'Foreman' ? Number(token.nameid) : Number(params.id)
	const handleTimeChange = async (
		timesheet: TimeSheet,
		activity: Activity,
		startTimeDate: Date,
		endTimeDate: Date,
	) => {
		updateTimeSheet(timesheet, activity, startTimeDate, endTimeDate)
	}	
	return (
		<>
			{open &&
				(timesheet.activities ?? []).map((activity: Activity, index: number) => {
					return (
						<TableRow key={index} style={{ background: '#fff' }}>
							<StyledTableCell />
							<StyledTableCell align='center'>
								<Chip label={'Activity'} variant={'activity'} />
							</StyledTableCell>
							<StyledTableCell align='left'>{activity.activity}</StyledTableCell>
							<StyledTableCell align='left'>
								{activity.activity === 'Constraint'
									? activity.type
									: activity.type.split(' > ').map(
											(wi, index, arr) =>
												index !== arr.length - 1 && (
													<>
														{index + 1}. {wi} <br />
													</>
												),
									  )}
							</StyledTableCell>
							<StyledTableCell align='right'>
								<TimePickerCell
									maxTime={activity.endTime}
									canEdit={!timesheet.confirmed}
									timeValue={activity.startTime}
									onChange={(newValue) => {
										handleTimeChange(timesheet, activity, newValue, activity.endTime)
									}}
								/>
							</StyledTableCell>
							<StyledTableCell align='right'>
								<TimePickerCell
									minTime={activity.startTime}
									canEdit={!timesheet.confirmed}
									timeValue={activity.endTime}
									onChange={(newValue) =>
										handleTimeChange(timesheet, activity, activity.startTime, newValue)
									}
								/>
							</StyledTableCell>
							<StyledTableCell align='right'>
								<AnimatedNumberField value={Number.isNaN(activity.hours) ? 0 : activity.hours} />
							</StyledTableCell>
							<StyledTableCell sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
								{activity.comment}
							</StyledTableCell>
							{activity.activity === 'Unproductive Time' ? (
								<StyledTableCell align='center'>
									<RemoveUnproductiveDialog onConfirm={async () => {

										await removeUnproductiveActivity(timesheet.id, activity.id, viewAsId)
									}} />
								</StyledTableCell>
							) : (
								<StyledTableCell />
							)}
						</TableRow>
					)
				})}
		</>
	)
}

export default ActivityRows
