import { Typography, Box, IconButton, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTokenClaims } from '../security/useTokenClaims'
import { getForemen } from '../services/backlogService'
import { Employee } from '../types/Employee/Employee'
import { ShiftEndSelectForeman } from '../components/reports/shift-end/ShiftEndSelectForeman'
import { useNavigate, useParams } from 'react-router-dom'
import { getDirectReports } from '../services/shiftEndReportService'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useShiftEndContext } from '../components/reports/shift-end/ShiftEndContext'

const ShiftEndSelectForemanSelectionPage = () => {
	const { id, role } = useTokenClaims()
	const [directReports, setDirectReports] = useState<Employee[]>()
	const [loading, setLoading] = useState(false)
	const [title, setTitle] = useState("");
	const params = useParams()
	const navigate = useNavigate()
	const { popFromNavPath, directReportNavPath, lastDirectReport} = useShiftEndContext()

	const loadData = async () => {
		setTitle("")
		setLoading(true)
		if (role.hierarchyIndex === 4) {
			// GF Layer
			const found = await getForemen(true, params.id ? parseInt(params.id) : id)
			setDirectReports(found)
			setLoading(false)
			return
		}

		const found = await getDirectReports(params.id ? parseInt(params.id) : id)
		setTitle(found[0].role.title + "s")
		setDirectReports(found)
		setLoading(false)
	}
	useEffect(() => {
		loadData()
	}, [params.id])
	const [firstRender, setFirstRender] = useState<boolean>(true);
	useEffect(() => {
		if (firstRender) {
			setFirstRender(false)
			return
		}
		if (directReportNavPath.length === 0) {
		  navigate(`/reports`);
		} else {
		  const last = lastDirectReport();
		  navigate(`/reports/${last}`);
		}

	  }, [directReportNavPath]);
	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' }}>
				<Box display={'flex'}>
					{params.id && (
						<IconButton
							color='default'
							onClick={() => {
								popFromNavPath()
							}}
						>
							<ArrowBackIcon sx={{ height: '2rem', width: 'auto' }} />
						</IconButton>
					)}
					<Typography
						sx={{
							fontSize: '1.5rem',
							paddingLeft: !params.id ? '1rem' : '0',
							paddingTop: '.5rem',
						}}
					>
						{title}
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', flex: 1, overflowY: 'auto' }}>
					{loading ? (
						<CircularProgress color='success' />
					) : (
						<ShiftEndSelectForeman foremen={directReports} />
					)}
				</Box>
			</Box>
		</>
	)
}

export default ShiftEndSelectForemanSelectionPage
