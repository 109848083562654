import ErrorIcon from '@mui/icons-material/Error'
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	SvgIconProps,
	SvgIconPropsColorOverrides,
	TextField,
	Typography,
} from '@mui/material'
import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close'

import PunchClockIcon from '@mui/icons-material/PunchClock'
import { Employee } from '../../../types/Employee/Employee'
import { confirmSupervisionTimesheet, getSupervisionTimesheet } from '../../../services/shiftEndReportService'
import dayjs from 'dayjs'

type TimeSheetDialogProps = {
	employee: Employee
}

export const TimeSheetDialog = ({ employee }: TimeSheetDialogProps) => {
	const [open, setOpen] = React.useState(false)
	const [startTime, setStartTime] = React.useState<Date>()
	const [endTime, setEndTime] = React.useState<Date>()
	const [loading, setLoading] = React.useState<boolean>(false)
	const [startRequired, setStartRequired] = React.useState(false)
	const [endRequired, setEndRequired] = React.useState(false)

	const loadData = async () => {
		setLoading(true)
		try {
			const timeSheet = await getSupervisionTimesheet(employee.id)

			if (timeSheet && timeSheet.activities && timeSheet.activities.length > 0) {
				setStartTime(timeSheet.activities[0].startTime)
				setEndTime(timeSheet.activities[0].endTime)
			} else {
				setStartTime(undefined)
				setEndTime(undefined)
			}
		} catch (error) {
			console.error('Failed to load timesheet data:', error)
		} finally {
			setLoading(false)
		}
	}

	const handleClickOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		setOpen(true)
		loadData().catch(console.error)
	}
	const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		setOpen(false)
	}

	const handleTextFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
	}

	const calculateHours = () => {
		const start = dayjs(startTime)
		const end = dayjs(endTime)

		// Calculate the difference in milliseconds
		const diff = end.diff(start)

		// Format the duration (e.g., hours and minutes)
		const durationFormatted = dayjs.duration(diff).format('HH:mm')

		return durationFormatted
	}
	const handleTimeChange = (
		value: string,
		setValue: React.Dispatch<React.SetStateAction<Date | undefined>>,
	) => {
		const timeParts = value.split(':')

		const updatedTime = dayjs()
			.set('hour', parseInt(timeParts[0]))
			.set('minute', parseInt(timeParts[1]))
			.toDate()

		setValue(updatedTime)
	}
	async function handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		if(!startTime || !endTime){
			!startTime && setStartRequired(true)
			!endTime && setEndRequired(true)
			return
		}
		setLoading(true)
		await confirmSupervisionTimesheet(employee.id, startTime, endTime)
		setLoading(false)
		setOpen(false)
	}

	return (
		<>
			<IconButton onClick={handleClickOpen} title='Time sheet'>
				<PunchClockIcon sx={{ fontSize: 28 }} color={employee.timesheetConfirmed ? 'success' : 'error'} />
			</IconButton>

			<Dialog open={open} onClose={handleClose} fullWidth>
				<div
					onClick={(e: React.MouseEvent<HTMLDivElement>) => {
						e.stopPropagation() // Prevent any event inside the dialog from bubbling up
					}}
				>
					<DialogTitle>
						{employee.firstName} {employee.lastName}&apos;s Time Sheet
					</DialogTitle>
					<IconButton
						onClick={handleClose}
						sx={(theme) => ({
							position: 'absolute',
							right: 8,
							top: 8,
							color: theme.palette.grey[500],
						})}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent>
						{loading ? (
							<CircularProgress color='success' />
						) : (
							<Box sx={{ display: 'flex', flexDirection: 'column' }} gap={4}>
								<FormControl fullWidth>
									<TextField
										onChange={(e) => handleTimeChange(e.target.value, setStartTime)}
										label='Start Time'
										type='time'
										InputLabelProps={{
											shrink: true,
										}}
										autoFocus
										error={startRequired}
										value={startTime ? dayjs(startTime).format('HH:mm') : ''}
										inputProps={{
											step: 300, // 5 min
										}}
									/>
								</FormControl>
								<FormControl fullWidth>
									<TextField
										onChange={(e) => handleTimeChange(e.target.value, setEndTime)}

										label='End Time'
										type='time'
										InputLabelProps={{
											shrink: true,
										}}
										autoFocus
										error={endRequired}
										value={endTime ? dayjs(endTime).format('HH:mm') : ''}
										inputProps={{
											step: 300, // 5 min
										}}
									/>
								</FormControl>

								<Typography>Total Hours: {calculateHours()}</Typography>
							</Box>
						)}
					</DialogContent>
					<DialogActions sx={{ justifyContent: 'space-between' }}>
						<Button color='warning' variant='contained' onClick={handleClose}>
							Cancel
						</Button>
						<Button color='success' variant='contained' onClick={handleSubmit}>
							Submit
						</Button>
					</DialogActions>
				</div>
			</Dialog>
		</>
	)
}
