import { Button, Chip, IconButton, TableRow } from '@mui/material'
import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { TimeSheet } from '../../../../types/Reports/ShiftEnd/TimeSheet'
import { StyledTableCell } from '../../../core/StyledTableCell'
import AnimatedNumberField from '../../../core/AnimatedNumberField'
import dayjs from 'dayjs'
import ActivityRows from './ActivityRows'

type WorkerRowProps = {
	timesheet: TimeSheet
	openDialog: (timeSheetId: number) => void
}

export function WorkerRow({ timesheet, openDialog }: WorkerRowProps) {
	const [open, setOpen] = useState(false)

	return (
		<>
			<TableRow key={timesheet.id}>
				<StyledTableCell>
					{(timesheet?.activities?.length ?? 0) > 0 && (
						<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</StyledTableCell>
				<StyledTableCell align='center'>
					<Chip label={'Worker'} variant={'worker'} />
				</StyledTableCell>
				<StyledTableCell align='left'>{timesheet.title}</StyledTableCell>
				<StyledTableCell align='center'></StyledTableCell>
				<StyledTableCell align='right'>
					{dayjs(timesheet.startTime).format('h:mm A') ?? 'n/a'}
				</StyledTableCell>
				<StyledTableCell align='right'>
					{dayjs(timesheet.endTime).format('h:mm A') ?? 'n/a'}
				</StyledTableCell>
				<StyledTableCell align='right'>
					<AnimatedNumberField
						value={Number.isNaN(timesheet.hours) ? 0 : timesheet.hours?.toFixed(1)}
					/>
				</StyledTableCell>
				<StyledTableCell
					align='left'
					// Comment field, enforce line wrapping for longer comments.
					sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
				></StyledTableCell>
				<StyledTableCell align='center'>
					{timesheet.confirmed ? (
						<Chip label={'Confirmed'} variant={'worker'} />
					) : (
						<Button
							variant='contained'
							color='success'
							onClick={() => {
								openDialog(timesheet.id)
							}}
						>
							Confirm
						</Button>
					)}
				</StyledTableCell>
			</TableRow>
			{/* Displayed when down arrow icon is clicked. */}
			<ActivityRows open={open} timesheet={timesheet} />
		</>
	)
}
