import { Employee } from '../types/Employee/Employee'
import { Activity } from '../types/Reports/ShiftEnd/Activity'
import { ShiftEndWorkItemsSummary } from '../types/Reports/ShiftEnd/ShiftEndWorkItems'
import { TimeSheet } from '../types/Reports/ShiftEnd/TimeSheet'
import { deleteOne, getAll, getOne, patch, post } from './httpService'
import dayjs from 'dayjs'

const getWorkItemsForSupervisor = async (supervisorId: number, date: Date) => {
	return await getAll<ShiftEndWorkItemsSummary[]>(
		`/TimeSheets/${supervisorId}/WorkItems?Date=${date.toISOString()}`,
	)
}

const getTimeSheetsForSupervisor = async (supervisorId: number, date: Date) => {
	return await getAll<TimeSheet[]>(
		`/TimeSheets?supervisorId=${supervisorId}&Date=${date.toISOString()}`,
	)
}

const getDirectReports = async (id: number): Promise<Employee[]> => {
	const result = await getAll<Employee[]>(
		`/Workers?reportsTo=${id}`,
		'Failed to get Direct Reports',
	)

	return result?.data ?? []
}

const confirmTimeSheet = async (timeSheetId: number) => {
	return await patch(`/TimeSheets/${timeSheetId}/Confirm`, {})
}

// const updateTimeSheetActivity = async (
// 	timesheetId: number,
// 	activityId: number,
// 	startTimeDate: Date,
// 	endTimeDate: Date,
// ) => {
// 	const startTime = dayjs(startTimeDate).toISOString()
// 	const endTime = dayjs(endTimeDate).toISOString()
// 	return await patch(`/TimeSheets/${timesheetId}/TimeRecord/${activityId}`, { startTime, endTime })
// }

const updateTimeSheetActivities = async (timesheetId: number, activities: Activity[]) => {
	return await patch(
		`/TimeSheets/${timesheetId}/TimeRecords`,
		activities.map((activity) => ({
			startTime: activity.startTime,
			endTime: activity.endTime,
			id: activity.id,
		})),
	)
}

const removeUnproductiveRecord = async (timesheetId: number, timeRecordId: number) => {
	return await deleteOne(`/TimeSheets/${timesheetId}/TimeRecords/${timeRecordId}`)
}

const confirmSupervisionTimesheet = async (supervisorId: number, startTime: Date, endTime: Date) => {
	return await post(`/TimeSheets/Supervision/${supervisorId}`, {startTime, endTime} )
}

const getSupervisionTimesheet = async (supervisionId: number) => {
	return (await getOne<TimeSheet | undefined>(
		`/TimeSheets/Supervision/${supervisionId}`,
	)).data
}

export {
	getWorkItemsForSupervisor,
	getTimeSheetsForSupervisor,
	confirmTimeSheet,
	updateTimeSheetActivities,
	removeUnproductiveRecord,
	getDirectReports,
	confirmSupervisionTimesheet,
	getSupervisionTimesheet
}
