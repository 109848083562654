import * as React from 'react'
import './styles.scss'
import { useParams } from 'react-router-dom'
import { getWorkItemsForSupervisor } from '../services/shiftEndReportService'
import { useShiftEndContext } from '../components/reports/shift-end/ShiftEndContext'
import { ShiftEndWorkItemsSummary } from '../types/Reports/ShiftEnd/ShiftEndWorkItems'
import WorkerSummaryGrid from '../components/reports/shift-end/workitemsumary/WorkerSummaryGrid'
import { useEffect, useState } from 'react'
import SearchBar from '../components/workitemsoverview/SearchBar'
import { useDecodeToken } from '../security/useDecodeToken'

const ShiftEndWorkItemsPartialPage = () => {
	const [workItemsSummary, setWorkItemsSummary] = React.useState<ShiftEndWorkItemsSummary[]>([])
	const [searchText, setSearchText] = useState<string>('')
	const [includeAllTasks, setIncludeAllTasks] = useState<boolean>(false)
	const [filteredItems, setFilteredItems] = useState<ShiftEndWorkItemsSummary[]>([])

	const token = useDecodeToken()
	const params = useParams()
	const viewAsId = token?.role === 'Foreman' ? Number(token.nameid) : Number(params.id)

	const { shiftDate } = useShiftEndContext()

	const loadData = async () => {
		// api call to fetch data
		if (viewAsId && shiftDate) {
			const result = await getWorkItemsForSupervisor(viewAsId, shiftDate)
			setWorkItemsSummary(result.data)
		}
	}

	useEffect(() => {
		loadData()
	}, [shiftDate])

	useEffect(() => {
		if (searchText === '') setFilteredItems(workItemsSummary)
		else {
			const filtered = (workItemsSummary || []).filter(
				(x) =>
					x.status.toLowerCase().includes(searchText.toLowerCase()) ||
					x.title.toLowerCase().includes(searchText.toLowerCase()) ||
					x.type.toLowerCase().includes(searchText.toLowerCase()) ||
					x.budgetedHours.toString().includes(searchText) ||
					x.burnedHours.toString().includes(searchText) ||
					x.percentComplete.toString().includes(searchText) ||
					x.performanceFactor.toString().includes(searchText) ||
					x.quantityBudgeted.toString().includes(searchText) ||
					x.tasks?.some(
						(t) =>
							t.status.toLowerCase().includes(searchText.toLowerCase()) ||
							t.title.toLowerCase().includes(searchText.toLowerCase()) ||
							t.type.toLowerCase().includes(searchText.toLowerCase()) ||
							t.burnedHours.toString().includes(searchText) ||
							t.percentComplete.toString().includes(searchText),
					),
			)

			if (!includeAllTasks) {
				const childFiltered = filtered.map((wi) => ({
					...wi,
					tasks: wi.tasks?.filter(
						(t) =>
							t.status.toLowerCase().includes(searchText.toLowerCase()) ||
							t.title.toLowerCase().includes(searchText.toLowerCase()) ||
							t.type.toLowerCase().includes(searchText.toLowerCase()) ||
							t.burnedHours.toString().includes(searchText) ||
							t.percentComplete.toString().includes(searchText),
					),
				}))
				setFilteredItems(childFiltered)
			} else setFilteredItems(filtered)
		}
	}, [workItemsSummary, searchText, includeAllTasks])

	const handleOnSearchChange = (searchText: string, includeAllTasks: boolean) => {
		setSearchText(searchText)
		setIncludeAllTasks(includeAllTasks)
	}

	return (
		<div className='page-layout'>
			<SearchBar onChange={handleOnSearchChange} searchPlaceholder='Filter Workitems' />
			<WorkerSummaryGrid workItemData={filteredItems} loadData={loadData} />
		</div>
	)
}

export default ShiftEndWorkItemsPartialPage
