import React, { createContext, useContext, useEffect, useState } from 'react'
import { showErrorToast, showSuccessToast } from '../../services/alertServices'
import { getAssignWorkersData, unassignWorker, assignWorker } from '../../services/laborService'
import { ThrownServiceError } from '../../types/Core/ThrownServiceError'
import { Employee } from '../../types/Employee/Employee'

interface LaborContextType {
	availableEmployees: Employee[] | undefined
	assignedEmployees: Employee[] | undefined
	unassignEmployee: (employee: Employee) => void
	assignEmployee: (employeeFrom: Employee, employeeTo?: Employee) => void
	loadData: () => Promise<void>
	searchedAvailable: Employee[] | undefined
	searchedAssignedIds: number[] | undefined
	setSearchText: React.Dispatch<React.SetStateAction<string>>
	searchText: string
}

const LaborContext = createContext<LaborContextType | null>(null)

const useLaborContext = (): LaborContextType => {
	const laborContextState = useContext(LaborContext)
	if (laborContextState === null) {
		throw new Error(
			'LaborContext not found. Try wrapping a parent component with <LaborContext.Provider>.',
		)
	}
	return laborContextState
}

type LaborProviderProps = {
	children?: React.ReactNode
}

const LaborProvider = ({ children }: LaborProviderProps) => {
	const [availableEmployees, setAvailableEmployees] = useState<Employee[] | undefined>()
	const [assignedEmployees, setAssignedEmployees] = useState<Employee[] | undefined>()

	const unassignEmployee = async (employee: Employee) => {
		await unassignWorker(employee).then(() => {
			showSuccessToast(`${employee.firstName} ${employee.lastName} was unassigned.`)
		})
		await loadData()
	}

	const loadData = async () => {
		const { availableWorkers, assignedWorkers } = await getAssignWorkersData()
		setAvailableEmployees(availableWorkers)
		setAssignedEmployees(assignedWorkers)
	}

	const assignEmployee = async (employeeFrom: Employee, employeeTo?: Employee) => {
		await assignWorker(employeeFrom, employeeTo)
			.then(async () => {
				showSuccessToast(
					`${employeeFrom.firstName} ${employeeFrom.lastName} was assigned to ${
						employeeTo ? `${employeeTo.firstName} ${employeeTo.lastName}` : 'undefined'
					}`,
				)
				await loadData()
			})
			.catch((err) => {
				const { eventId, message } = err as ThrownServiceError
				showErrorToast({
					eventId: eventId ?? '',
					traceId: 'projectService',
					message: message ?? '',
				})
			})
	}

	// Search
	const [searchText, setSearchText] = useState('')
	const [searchedAvailable, setSearchedAvailable] = useState<Employee[]>()
	const [searchedAssignedIds, setsearchedAssignedIds] = useState<number[]>()

	useEffect(() => {
		if (searchText.trim() === '') {
			setSearchedAvailable(undefined)
			setsearchedAssignedIds(undefined)
			return
		}

		const lowerCaseSearchTerm = searchText.toLowerCase()

		// Filter available employees
		const filtered = availableEmployees?.filter((employee) => {
			const fullName = `${employee.firstName.toLowerCase()} ${employee.lastName.toLowerCase()}`
			return fullName.includes(lowerCaseSearchTerm)
		})
		setSearchedAvailable(filtered)

		const highlightedAssignedWorkers: number[] = [];
		const recursiveSearch = (employees: Employee[], supervisors: number[] = []) => {
			employees.forEach((employee) => {
				const currentPath = [...supervisors, employee.id];
				const fullName = `${employee.firstName.toLowerCase()} ${employee.lastName.toLowerCase()}`
				// Check if the employee matches the search term
				if (fullName.includes(lowerCaseSearchTerm)) {
					highlightedAssignedWorkers.push(...currentPath); // Add supervisors and the matched employee
					return; // Stop searching deeper (exclude direct reports)
				}
		
				// Recurse into direct reports if no match is found
				if (employee.directReports) {
					recursiveSearch(employee.directReports, currentPath);
				}
			});
		};
		recursiveSearch(assignedEmployees ?? [])
		setsearchedAssignedIds(Array.from(new Set(highlightedAssignedWorkers)));
	}, [searchText])

	return (
		<LaborContext.Provider
			value={{
				availableEmployees,
				assignedEmployees,
				assignEmployee,
				unassignEmployee,
				loadData,
				searchText,
				setSearchText,
				searchedAssignedIds,
				searchedAvailable
			}}
		>
			{children}
		</LaborContext.Provider>
	)
}

export { useLaborContext, LaborProvider }
