import { Chip, TableRow } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Employee } from '../../../types/Employee/Employee'
import { StyledTableCell } from '../../core/StyledTableCell'
import { useShiftEndContext } from './ShiftEndContext'
import { TimeSheetDialog } from './TimesheetDialog'

type ForemanRowProps = {
	foreman: Employee
}

export function ShiftEndForemanRow({ foreman: directReport }: ForemanRowProps) {
	const navigate = useNavigate()
	const { appendToNavPath } = useShiftEndContext()
	return (
		<>
			<TableRow
				onClick={() => {
					if (directReport.role.title === 'Foreman') navigate(`/reports/${directReport.id}/Foreman`)
					else {
						appendToNavPath(directReport)
						navigate(`/reports/${directReport.id}`)
					}
				}}
				key={directReport.id}
				sx={{ cursor: 'pointer' }}
			>
				<StyledTableCell align='left'>
					{directReport.firstName} {directReport.lastName}
				</StyledTableCell>
				<StyledTableCell align='center'><TimeSheetDialog employee={directReport}/></StyledTableCell>
				<StyledTableCell align='center'>{directReport.employeeId}</StyledTableCell>
				<StyledTableCell align='center'>{directReport.primaryCraft}</StyledTableCell>
				<StyledTableCell align='center'>{directReport.classification}</StyledTableCell>
				<StyledTableCell align='center'>
					<Chip label={'Online'} variant={'Online'} />
				</StyledTableCell>
				<StyledTableCell align='center'>
					<Chip
						label={directReport.hasUnconfirmedTimeSheets ? 'Not Submitted' : 'Submitted'}
						variant={directReport.hasUnconfirmedTimeSheets ? 'failure' : 'success'}
					></Chip>
				</StyledTableCell>
			</TableRow>
		</>
	)
}
